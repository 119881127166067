import React, { FC } from 'react';
import ws from 'isomorphic-style-loader/withStyles';
import history from 'utils/history';
import Confirm from './Confirm';
import s from './ConfirmPage.less';

const ConfirmPage: FC<{ confirmToken?: string; redirect?: boolean }> = ({
  confirmToken,
  redirect = true,
}) => {
  const handleConfirm = () => {
    if (redirect && history) history.push('/');
  };

  return (
    <div className={s.root}>
      <Confirm onSuccess={handleConfirm} confirmToken={confirmToken} />
    </div>
  );
};

export default ws(s)(ConfirmPage);
